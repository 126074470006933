<template>
  <div>
    <router-view
      v-if="game"
      :game="game"
      :player-id="currentPlayerId"
      :ready-to-render="isExpectedRouteName"
      ccpm
    />
    <fever-chart-container
      v-if="game"
      :game="game"
      :visible.sync="feverChartOpened"
    />
    <ag-float-button-container left>
      <v-btn
        color="accent"
        fab
        @click="feverChartOpened = true"
      >
        <v-icon>mdi-scatter-plot</v-icon>
      </v-btn>
    </ag-float-button-container>
  </div>
</template>

<script>
import _throttle from 'lodash/throttle'
import Helpers from 'src/utils/helpers'
import Repositories from 'src/repositories'
import { mapActions, mapGetters } from 'vuex'

import Repository from 'src/services/repository'

import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import FeverChartContainer from './FeverChartContainer'

import AVAILABLE_STATUS from '../availableStatus'

export default {
  name: 'MultiProjectCcpmContainer',
  components: {
    AgFloatButtonContainer,
    FeverChartContainer,
  },
  props: {
    gameId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    game: null,
    feverChartOpened: false,
  }),
  computed: {
    ...mapGetters('me', {
      trainingSessionPlayerId: 'trainingSessionPlayerId',
    }),
    ...mapGetters('session', {
      gameStatusListByResourceId: 'gameStatusListByResourceId',
    }),
    gameStatus () {
      return this.gameStatusListByResourceId[this.gameId]
    },
    currentPlayer () {
      return this.game?.playerList
        ?.find(player => player.trainingSessionPlayerId === this.trainingSessionPlayerId)
    },
    currentPlayerId () {
      return this.currentPlayer?.id
    },
    currentPlayerStatus () {
      return this.currentPlayer?.status
    },
    expectedRouteName () {
      if (this.currentPlayer?.auto) {
        return 'multiProjectCcpmAutomatic'
      }
      return this.$options.expectedRoutesForStatus[this.currentPlayerStatus]
    },
    isExpectedRouteName () {
      return this.$route.name === this.expectedRouteName
    },
  },
  sockets: {
    multiProjectSessionBulkChanges () {
      this.fetchData()
    },
    multiProjectSessionPlayerWaitForStart ({ multiProjectSessionPlayerId }) {
      if (multiProjectSessionPlayerId !== this.currentPlayerId) {
        this.updatePlayerStatus({
          id: multiProjectSessionPlayerId,
          status: AVAILABLE_STATUS.waitForStart,
        })
      }
    },
    multiProjectSessionPlayerAutoModeChanged ({ multiProjectSessionPlayerId }) {
      if (multiProjectSessionPlayerId === this.currentPlayerId) {
        this.fetchData()
      }
    },
  },
  watch: {
    expectedRouteName () {
      this.ensureRouteIsValid()
    },
    gameStatus () {
      if (this.gameStatus !== 'opened') {
        this.$router.go(-1)
      }
    },
  },
  mounted () {
    this.fetchData()
    this.setTitle()
  },
  methods: {
    ...mapActions('ui', {
      setTitle: 'setTitle',
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    fetchData: _throttle(async function fetchData () {
      this.game = await Repositories.multiproject.get(this.gameId)
    }, 200, { leading: true, trailing: true }),
    ensureRouteIsValid () {
      if (this.expectedRouteName && !this.isExpectedRouteName) {
        this.$router.replace({ name: this.expectedRouteName })
      }
    },
    updatePlayerStatus ({ id, ...updatedFields }) {
      this.game.playerList = Helpers.patchItem({
        collection: this.game.playerList,
        identifier: 'id',
        patch: { id, ...updatedFields },
      })
    },
    async sendPlayerAction (actionName) {
      try {
        await Repositories.multiprojectPlayer.sendAction({
          gameId: this.game.id,
          playerId: this.currentPlayerId,
          actionName,
        })
        await this.fetchData()
      }
      catch (error) {
        this.showErrorNotification(Repository.utils.parseErrorMessage(error))
        throw error
      }
    },
  },
  provide () {
    return {
      sendPlayerAction: this.sendPlayerAction,
    }
  },
  expectedRoutesForStatus: {
    [AVAILABLE_STATUS.needToSelectCriticalResource]: 'multiProjectCcpmWaitingCriticalResource',
    [AVAILABLE_STATUS.notReady]: 'multiProjectCcpmWelcome',
    [AVAILABLE_STATUS.waitForStart]: 'multiProjectCcpmWelcome',
    [AVAILABLE_STATUS.mustStartProject]: 'multiProjectCcpmStart',
    [AVAILABLE_STATUS.resourcesSelection]: 'multiProjectCcpmResourcesSelection',
    [AVAILABLE_STATUS.waitForResourcesCapacity]: 'multiProjectCcpmWaitForRessource',
    [AVAILABLE_STATUS.capacityReceived]: 'multiProjectCcpmCapacityReceived',
    [AVAILABLE_STATUS.waitToStartNewWeek]: 'multiProjectCcpmWaitNewWeek',
    [AVAILABLE_STATUS.projectFinished]: 'multiProjectCcpmProjectFinished',
  },
}
</script>
